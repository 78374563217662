body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

body {
  background-color: rgb(35, 37, 41);
  color: aliceblue;
}

button {
  color: black;
}

h3 {
  color: aliceblue;
}

input {
  background-color: rgb(35, 37, 41);
  color: aliceblue;
}

.bank-slots {
  height: 20px;
  text-align: center;
  margin: 10px;
  font-style: italic;
}

.border-top {
  border-top: 1px solid aliceblue;
}

.button {
  font-size: 2em;
  margin: 2vh 0 0 1vw;
  padding: 10px 20px;
  text-transform: uppercase;
  background: #0ea0f5;
  border: 2px solid #0ea0f5;
  color: #fff;
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.4s;
}

.centered {
  text-align: center;
}

.char {
  color: #d7bef6;
  font-size: 1.5em;
  padding: 1vh 0.5vw;
}

.checkbox-container-inner {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.checkbox-container-outer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.container {
  display: flex;
  padding: 1vh 1vw;
}

.error {
  color: red;
  margin-top: 10;
  height: 30px;
}

.message {
  color: green;
  margin-top: 10;
  height: 30px;
}

.filter {
  border-radius: 20px;
  border: 2px solid #0ea0f5;
  font-size: 1.75em;
  transition: all 0.4s;
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.gold-table {
  width: 80%;
  margin-bottom: 20px;
}

.greeting {
  color: aliceblue;
  display: flex;
  justify-content: center;
  text-align: left;
}

.normal-text {
  color: aliceblue;
}

.highlighted {
  color: yellow;
}

.gold {
  color: #f4e387;
}

.bold {
  font-weight: bold;
}

.input {
  border-radius: 20px;
  border: 2px solid #0ea0f5;
  font-size: 1.75em;
  transition: all 0.4s;
  padding: 5px;
  margin-left: 5px;
  margin-top: 10px;
}

.inputContainer {
  display: flex;
}

.spacer {
  margin: 0 1em;
  text-align: left;
}

.text-right {
  text-align: right;
}

.total-gold {
  height: 30px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-gold > span {
  margin-left: 10px;
}

.total-gold-placeholder {
  height: 30px;
  margin-top: 10px;
}

#item-table > tbody > tr:nth-child(odd) {
  background-color: #3C3C3C;
}


